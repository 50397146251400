// RelatedDrugsPopup.js
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import NewEncounterFormStyle from './NewEncounterForm.module';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import TableComponent from '../../ui/Table';
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";

const MedicalSelections = ({
    selectionType,
    title,
    open,
    handleClose,
    loading,
    data,
    error,
    selectOption,
    alternative,
    count,
    handlePageChange
}) => {
    const classes = NewEncounterFormStyle();
    const { t } = useTranslation('newEncounter');
    const [searchQuery, setSearchQuery] = useState('');
    const [options, setOptions] = useState('');
    const [page, setPage] = useState(1)

    useEffect(() => {
        if (searchQuery) {
            if (selectionType === 'icd' || selectionType === 'cpt') {
                setOptions(
                    data.filter((option) =>
                        option.shortdescription
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                    )
                );
            } else if (selectionType === 'drug') {
                setOptions(data.filter((option) => option.product_brand_name.toLowerCase().includes(searchQuery.toLowerCase())))
            }
        } else {
            setOptions(data);
        }
    }, [searchQuery, data, selectionType]);

    const handleDialogPageChange = (e, v) => {
        setPage(v)
        handlePageChange(v)
      }

    useEffect(() => {
        if (alternative && !open) {
          setPage(1)
          handlePageChange(1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [alternative, open]);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle sx={classes.dialog_title}>{t('DialogTitle', {option: title})}</DialogTitle>
            <DialogContent sx={classes.dialogContent}>
                {loading ? (
                    <Box sx={classes.alternative_drugs_circular}>
                        <CircularProgress />
                    </Box>
                ) : error || data.length === 0 ? (
                    <Alert severity="error">
                        <AlertTitle sx={classes.alertTitle}>{title}</AlertTitle>
                        {`No ${title} Found`}
                    </Alert>
                ) : (
                    <>
                    <Box sx={{ marginBottom: '20px', marginLeft: '40px' }}>
                        <TextField
                            variant="outlined"
                            InputProps={{
                                startAdornment: searchQuery && (
                                    <InputAdornment position="start">
                                        <IconButton
                                            onClick={() => setSearchQuery('')}
                                            sx={classes.clear_button}
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            placeholder={t('Search')}
                            size="small"
                            onChange={(e) => setSearchQuery(e.target.value)}
                            value={searchQuery}
                            sx={{ width: '35%' }}
                        />
                    </Box>

                    <TableComponent
                        tableHead={
                            selectionType === 'icd' ? [t('DiagnoseCode'), t('Description')] :
                            selectionType === 'cpt' ? [t('ProcedureCode'), t('Description')] :
                            selectionType === 'drug' ? [t('Alternative_Dialog.Brand'), t('Alternative_Dialog.Label'), t('Alternative_Dialog.UOM'), t('Alternative_Dialog.PackageCount'), t('Alternative_Dialog.packageSize'), t('Alternative_Dialog.Price')] :
                            null
                        }
                        tableBody={options}
                        bodyCells={
                            selectionType === 'icd' ? ['icdcode', 'shortdescription'] :
                            selectionType === 'cpt' ? ['cptcode', 'shortdescription'] :
                            selectionType === 'drug' ? ['product_brand_name', 'product_label_name', 'med_strength_uom', 'product_package_count', 'product_package_size', 'unitpricetopublic'] :
                            null
                        }
                        customStyle={{
                            headerTableCell: classes.header_table_cell,
                            row: classes.alternative_table_row,
                            tableCell: classes.table_cell,
                        }}
                        clickHandler={(row) => selectOption(row)}
                    />
                </>
                )}
            </DialogContent>
            <DialogActions sx={{display: 'grid', justifyContent: 'center'}}>
            {alternative && count > 1 && (
              <Box sx={classes.pagination}>
                <Stack spacing={2}>
                  <Pagination
                    page={page}
                    disabled={count === 1}
                    count={count}
                    variant="outlined"
                    color="primary"
                    size="large"
                    onChange={handleDialogPageChange}
                  />
                </Stack>
              </Box>
            )}
                <Button
                    onClick={() => {
                        handleClose();
                        setSearchQuery('');
                    }}
                    color="primary"
                    variant='contained'
                >
                    {t('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MedicalSelections;
