import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ChartsStyle from './Charts.Module.js'
import { Chart } from "react-google-charts";
import CircularProgress from '@mui/material/CircularProgress';

const PieChartGoogle = ({ data, title, hideTitle, slices, notFoundDataMessage }) => {
    const classes = ChartsStyle()
    const options = {
        slices,
        legend: {'position':'right','alignment':'center'},
        width: 400,
        height: 300,
        chartArea: {
          left: 10,
          top: 10,
          width: "100%",
          height: "100%",

        },
    };

    if (!data) {
        return <Box sx={classes.notFoundMessageContainer}>
          <Typography sx={classes.notFoundMessageTitle}><CircularProgress/></Typography>
        </Box>
      }

    if (Array.isArray(data) && data.length === 0) {
        return <Box sx={classes.notFoundMessageContainer}>
          <Typography sx={classes.notFoundMessageTitle}>{notFoundDataMessage}</Typography>
        </Box>
      }

    return (
        <Box sx={{width: '400px', height: '400px'}}>
            {!hideTitle && <Typography sx={classes.pieTitle}>{title}</Typography>}
            {data && <Chart
                chartType="PieChart"
                data={data || []}
                options={options}
                width={"100%"}
                height={"400px"}
            />}
        </Box>
    );
}

export default PieChartGoogle
