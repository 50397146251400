import SimpleImageSlider from 'react-simple-image-slider';
import { sliderImages } from '../config/variables';

import classes from './ImageSlider.module.css'
const images = sliderImages()
const ImageSlider = () => {
    return (
        <div className={classes.outer_container}>
            <div className={classes.slider_container}>
                <SimpleImageSlider
                    width="100%"
                    height="100%"
                    images={images}
                    showBullets={true}
                    slideDuration={1.5}
                    loop={true}
                    autoPlay={true}
                    autoPlayDelay={5}
                    bgColor="#fff"
                />
            </div>
        </div>
    );
};

export default ImageSlider;
