import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, NavLink } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReplayIcon from '@mui/icons-material/Replay';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import useAxios from '../../hooks/useAxios';
import AuthContext from '../../contexts/auth-context';
import AppContext from '../../contexts/app-context';
import UserProfilePageStyle from './UserProfilePage.module';
import EncounterCardInsurance from '../../components/users/EncounterCard/EncounterCardInsurance';
import EncounterCard from '../../components/users/EncounterCard/EncounterCard';
import { urls, PAGE_SIZE, app_paths, toastify_status, searchByStateButtons, SearchBarMenuItems, companiesObject, company } from '../../components/config/variables'
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import SearchInput from '../../components/ui/SearchInput';
import {useTranslation} from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress';
import TableComponent from '../../components/ui/Table';
import { validateUserResponse, validateUserEncounters, validatePatientDiagnosis, validatePatientProfileDrugsCode, validatePatientProcedures } from '../../Utils/validateHttpResponse';
import Toastify from '../../components/ui/Toastify';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const PatientProfilePage = () => {
  const classes = UserProfilePageStyle();
  const [patientInfo, setPatientInfo] = useState(undefined);
  const [patientError, setPatientError] = useState(undefined);
  const [patientEncounters, setPatientEncounters] = useState([]);
  const [patientEncounterCards, setPatientEncounterCards] = useState([]);
  const [patientEncountersError, setPatientEncountersError] = useState(undefined);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [dateValidateError, setDateValidateError] = useState(false);
  const [loadingEcounters, setLoadingEncounters] = useState(true);
  const [patientId, setPatientId] = useState(undefined);

  const { operation } = useAxios();
  const authCtx = useContext(AuthContext);
  const appCtx = useContext(AppContext);
  const token = authCtx.token;
  const userRole = appCtx.userRole;
  const direction = appCtx.direction
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchByState, setSearchByState] = useState('');

  const [disaabledSearchButton, setDisabledSearchButton] = useState(true);
  const [searchButtonClicked, setSearchButtonClicked] = useState(false);
  const {t} = useTranslation('doctorProfilePage')
  const [patientDiagnosis, setPatientDiagnosis] = useState(undefined);
  const [patientProcedures, setPatientProcedures] = useState(undefined);
  const [PatientMedications, setPatientMedications] = useState(undefined);

  const [openDiagnosisMenu, setOpenDiagnosisMenu] = useState(false);
  const [openProceduresMenu, setOpenProceduresMenu] = useState(false);
  const [openMedicationsMenu, setOpenMedicationsMenu] = useState(false);

  const SearchByMenuItems = SearchBarMenuItems({t, userRole, hide:['patient_name']});
  
  // Javascript is case sensitive, so patientId <> patientID
  const {patientID} = useParams();
  /* to be called from the useEffect only if the page refreshes */
  const getPatientFromPatientID = () => {
    operation(
      {
        method: 'GET',
        url: urls.GET_A_PATIENT + patientID,
        headers: { Authorization: `token ${token}` },
        data: {},
      },
      handleSetPatientInfo,
      setPatientError
    );
  }

  const handleSetPatientInfo = (user) => {
    if(validateUserResponse(user)) {
      setPatientInfo(user)
    } else {
      console.log('invalid user response')
      Toastify({message: 'invalid user response', state: toastify_status.ERROR})
    }
  }
  
  useEffect(() => {
    if (typeof patientError !== 'undefined') {
      console.log (patientError)
    }
  }, [patientError]);

  useEffect(() => {
    // set page = 1 if selected user is changed
    setPage(1)
    if (typeof appCtx.selectedUser.user !== 'undefined') {
      setPatientId(appCtx.selectedUser.id);
      setPatientInfo(appCtx.selectedUser);
    }
    else {
      setPatientId(patientID);
      getPatientFromPatientID()
    }
    // eslint-disable-next-line
  }, [JSON.stringify(appCtx.selectedUser)]);

  const getPatientEncountersFromPatientId = (filter) => {
    setLoadingEncounters(true)
    operation(
      {
        method: "GET",
        //url comes from the context to seperate patient profile
        url: urls.GET_USER_ENCOUNTERS + patientId + filter,
        headers: { Authorization: `token ${token}` },
        data: {},
      },
      getPatientEncounters,
      setPatientEncountersError
    );
  }

  useEffect(() => {
    if (typeof patientId !== 'undefined') {
        let filter
      if(searchButtonClicked) {
        if (searchBy === 'date' && fromDate && toDate) {
          filter = `?from_date=${fromDate}&to_date=${toDate}&page=${page}&page_size=${PAGE_SIZE}`;
        } else if (searchBy === 'state') {
          filter = `?state=${searchByState}&page=${page}&page_size=${PAGE_SIZE}`
        } else {
        //search by
        const selectedSearchBy =
        searchBy === 'provider_name'   ? '?provider=' :
        searchBy === 'icd_code'   ? '?icd=' :
        searchBy === 'cpt_code'   ? '?cpt=' :
        searchBy === 'drugname'   ? '?drugname=' :
        searchBy === 'state'      ? '?state=' :
        '';

        //search query
        const selectedSearchQuery = searchBy === "provider_name" ? searchQuery.toLowerCase() : searchQuery

          filter = searchQuery
            ? `${selectedSearchBy}${selectedSearchQuery}&page=${page}&page_size=${PAGE_SIZE}`
            : `?page=${page}&page_size=${PAGE_SIZE}`;
        }

        getPatientEncountersFromPatientId(filter);
      } else {
        getPatientEncountersFromPatientId(`?page=${page}&page_size=${PAGE_SIZE}`);
      }

    }
    // eslint-disable-next-line
  }, [patientId, page, searchButtonClicked]);

  const navigate = useNavigate();

  const handleChangeSearchBy = (event) => {
    setSearchBy(event.target.value);
    setDateValidateError(false);
    setSearchQuery('');
    setFromDate('')
    setToDate('')
    setSearchByState('')
    setSearchButtonClicked(false)
    setPage(1)
  };

  const handleChangeSearch = (e) => {
    const {value, name} = e.target

    if (searchBy === 'date') {
      const currentDate = new Date();
      const date = new Date(value);

      if (date < currentDate) {
        if(name === 'fromDate'){
          setFromDate(value);
        } else if (name === 'toDate'){
          setToDate(value);
        }
        setDateValidateError(false);
      } else {
        setDateValidateError(true);
      }

    } else {
      setSearchQuery(value);
    }
  };

  // set date validate to error if from date bigger than to date
  useEffect(() => {
    if(toDate){
      if(fromDate > toDate){
        setDateValidateError(true);
      } else {
        setDateValidateError(false);
      }
    } 
  }, [fromDate, toDate])

   // set disabled search button to true if there is date validate error of there is no search fromDate or searchQuery
   useEffect(() => {
    const isValidDate = fromDate && toDate && !dateValidateError;
    const isValidSearch = searchQuery && !dateValidateError;

    if (isValidDate || isValidSearch) {
      setDisabledSearchButton(false);
    } else {
      setDisabledSearchButton(true);
    }
  }, [fromDate, toDate, searchQuery, dateValidateError]);

  const handleSearch = (state) => {
    setPage(1);
    setSearchButtonClicked(true);
    if (searchBy === 'date') {
      getPatientEncountersFromPatientId(`?from_date=${fromDate}&to_date=${toDate ? toDate : fromDate}&page=1&page_size=${PAGE_SIZE}`);
    } else if (searchBy === 'provider_name') {
      getPatientEncountersFromPatientId(`?provider=${searchQuery.toLowerCase()}&page=1&page_size=${PAGE_SIZE}`);
    } else if (searchBy === 'cpt_code') {
      getPatientEncountersFromPatientId(`?cpt=${searchQuery}&page=1&page_size=${PAGE_SIZE}`);
    } else if (searchBy === 'drugname') {
      getPatientEncountersFromPatientId(`?drugname=${searchQuery}&page=1&page_size=${PAGE_SIZE}`);
    } else if (searchBy === 'icd_code') {
      getPatientEncountersFromPatientId(`?icd=${searchQuery}&page=1&page_size=${PAGE_SIZE}`);
    } else if (searchBy === 'state') {
      getPatientEncountersFromPatientId(`?state=${state}&page=1&page_size=${PAGE_SIZE}`);
    } else {
      console.log ("Unknown filter")
    }
  };

  const defaultSearch = () => {
    setSearchButtonClicked(false)
    if(page !== 1) {
      setPage(1);
    } else {
      getPatientEncountersFromPatientId(`?page=${page}&page_size=${PAGE_SIZE}`)
    }
  }

  const handleClearSearch = () => {
      setSearchQuery('');
      setSearchBy('')
      setFromDate('')
      defaultSearch()
  };

  const handleClearDate = (value) => {
    if(value === 'fromDate'){
      setFromDate('')
    } else if (value === 'toDate'){
      setToDate('')
    }
    setDateValidateError(false);
    
    defaultSearch()
  }

  // call handle clear search and clear to date to reload patient encounters and clear any search
  const reloadPatientEncounters = () => {
    handleClearSearch()
    setToDate('')
  }

  const sortByDate = (array) => {
    return array.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;
    });
  };

  const storePatientEncounterCards = (encounters) => {
    if (typeof encounters !== 'undefined') {
      return encounters.map((patientEncounter) => {
        switch (userRole) {
          case "insurance_admin":
            return <EncounterCardInsurance key={patientEncounter.id} encounter={patientEncounter} />;
          case "doctor":
          case "facility_admin":
          case "pharmacy_admin":
          case "patient":
              return <EncounterCard key={patientEncounter.id} encounter={patientEncounter} />;
          default:
            return <p key={patientEncounter.id}>Something went wrong</p>;
        }
      });
    }
  }

  const getPatientEncounters = (encounters) => {
    if(validateUserEncounters(encounters)) {
      setLoadingEncounters(false)
      const sortedPatientEncounters = sortByDate(encounters.results);
      setPatientEncounters(sortedPatientEncounters);
      setPatientEncounterCards(storePatientEncounterCards(sortedPatientEncounters))
      setCount(Math.ceil(encounters.count / PAGE_SIZE));
    } else {
      console.log('invalid user encounters response')
      Toastify({message: 'invalid user encounters response', state: toastify_status.ERROR})
    }
  };

  const arrowBack = () => {
    if (appCtx.userRole !== 'patient') {
      return (
        <IconButton size="small" sx={classes.arrow_button} onClick={() => navigate(-1)}>
          <ArrowBackIcon fontSize="small" sx={classes.arrow_icon} />
        </IconButton>
      )
    }
    else {
      return null
    }
  }

  const reloadEncounter = () => {
      return (
        <IconButton size="small" sx={classes.arrow_button} onClick={reloadPatientEncounters}>
          <ReplayIcon fontSize="small" sx={classes.arrow_icon} />
        </IconButton>
      )
  }

  useEffect(() => {
    if(patientID){
      fetchDiagnosis()
      fetchMedications()
      if(company !== companiesObject.speedyRecovery && userRole !== 'pharmacy_admin') {
        fetchProcedures()
      }
    }
    // eslint-disable-next-line
  }, [patientID])

  const fetchDiagnosis = () => {
    operation(
      {
        method: "GET",
        url: urls.GET_PATIENT_Diagnosis + patientID,
        headers: { Authorization: `token ${token}` },
        data: {},
      },
      handleSetPatientDiagnosis,
      (err) => console.log(err)
    );
  }

  const handleSetPatientDiagnosis = (patientDiagnosisResponse) => {
    if(validatePatientDiagnosis(patientDiagnosisResponse)) {
      setPatientDiagnosis(patientDiagnosisResponse)
    } else {
      console.log('invalid diagnosis response')
      Toastify({message: 'invalid diagnosis response', state: toastify_status.ERROR})
    }
  }

  const fetchProcedures = () => {
    operation(
      {
        method: "GET",
        url: urls.GET_PATIENT_Procedures + patientID,
        headers: { Authorization: `token ${token}` },
        data: {},
      },
      handleSetPatientProcedures,
      (err) => console.log(err)
    );
  }

  const handleSetPatientProcedures = (patientProceduresResponse) => {
    if(validatePatientProcedures(patientProceduresResponse)) {
      setPatientProcedures(patientProceduresResponse)
    } else {
      Toastify({message: 'invalid Procedures response', state: toastify_status.ERROR})
    }
  }

  const fetchMedications = () => {
    operation(
      {
        method: "GET",
        url: urls.GET_PATIENT_Medications + patientID,
        headers: { Authorization: `token ${token}` },
        data: {},
      },
      handleSetPatientMedications,
      (err) => console.log(err)
    );
  }

  const handleSetPatientMedications = (patientMedicationsResponse) => {
    if(validatePatientProfileDrugsCode(patientMedicationsResponse)) {
      setPatientMedications(patientMedicationsResponse)
    } else {
      console.log('invalid medications response')
      Toastify({message: 'invalid medications response', state: toastify_status.ERROR})
    }
  }

  const handleOpenDiagnosisMenu = () => {
    setOpenDiagnosisMenu((preV) => !preV);
  };

  const handleOpenProceduresMenu = () => {
    setOpenProceduresMenu((preV) => !preV);
  };

  const handleOpenMedicationsMenu = () => {
    setOpenMedicationsMenu((preV) => !preV);
  };

 // this function called when select search by encounter state and click state button
  const handleSelectEncounterState = (btn) => {
    if(btn.name === searchByState) {
      setSearchByState('')
      defaultSearch()
    } else {
      handleSearch(btn.name)
      setSearchByState(btn.name)
    }
  }

  const PatientMedicalTable = ({
    open,
    handleToggle,
    disabled,
    isActive,
    buttonLabel,
    tableHead,
    tableBody,
    bodyCells,
  }) => {
    return (
      <Box>
        <Button
          onClick={handleToggle}
          disabled={disabled}
          sx={[
            classes.patient_profile_button({ isActive }),
            direction === 'ltr' ? { marginRight: '20px' } : { marginLeft: '20px' },
          ]}
        >
          {buttonLabel}
        </Button>
        <Dialog
          open={open}
          onClose={handleToggle}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle sx={{textAlign: 'center'}}>{buttonLabel}</DialogTitle>
          <DialogContent>
            <Box sx={classes.medical_table_box}>
              <TableComponent
                tableHead={tableHead}
                tableBody={tableBody}
                bodyCells={bodyCells}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant='contained'
              sx={classes.medical_table_close_button}
              onClick={handleToggle}>
              {t('Search.Close')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  return !patientInfo ? (
    // <Alert severity="error">
    // <Alert>
    //   <AlertTitle>Patient Not Found</AlertTitle>
    // </Alert>
    <CircularProgress sx={{ marginLeft: '50%', transform: 'translate(-50%, -50%)' }}/>
  ) : (
    <Box>
      {arrowBack()}
      <Box sx={classes.container}>
        <Box sx={classes.user_info_container} dir={direction}>
          <Box sx={classes.avatar_container}>
            <Avatar
              alt="user1"
              src={patientInfo.user?.gender === 'F' ? '/images/patient_woman.svg' : '/images/patient_man.svg'}
              sx={classes.user_avatar}
            />
            <Typography sx={classes.user_name}>{patientInfo.user?.name}</Typography>
            {(userRole === 'doctor' || userRole === 'insurance_admin') && (
            <NavLink to={app_paths.UPDATE_PATIENT.replace(':patientID', patientID)} style={classes.new_encounter_link}>
             <Button sx={[
                classes.patient_profile_button,
                direction === 'ltr' ? { marginRight: '20px' } : { marginLeft: '20px' },
              ]}>
              {t('newPatient:UpdatePatient')}
            </Button>
          </NavLink>
          )}

          </Box>
          <Box sx={classes.user_details}>

            <Box sx={classes.user_info_box}>
              <Typography>
               {t('GovernmentID')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo.user?.eid}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
               {t('DateOfBirth')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo.user?.birthdate}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
               {t('Age')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo.user?.age} Y
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
               {t('Gender')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo.user?.gender === 'F' ? 'Female' : 'Male'}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
               {t('Nationality')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo.user?.nationality}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
               {t('Employer')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo.user?.employer}
                </Typography>
            </Box>

            <Box sx={classes.user_email_box}>
              <Typography>
               {t('EmailAddress')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo.user?.email}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
               {t('MobileNumber')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo.user?.phone_number}
                </Typography>
            </Box>
            <Box sx={classes.user_address_box}>
              <Typography>
               {t('PhysicalAddress')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo.user?.physical_address}
                </Typography>
            </Box>
            <Box sx={classes.user_insurance_container}>
              <Typography sx={classes.user_insurance_title}>
                {t('PrimaryInsurance')}
              </Typography>
              <Box sx={classes.user_insurance_box}>
                <Typography>{t('Name')}</Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo?.primary_insurance_name}
                </Typography>
              </Box>

              <Box sx={classes.user_insurance_box}>
                <Typography>{t('ID')}</Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo?.primary_insurance_name === 'Out of Pocket'
                    ? 'Out of pocket'
                    : patientInfo?.patient_primary_insurance_id}
                </Typography>
              </Box>

              <Box sx={classes.user_insurance_box}>
                <Typography>{t('Expiration')}</Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo?.primary_insurance_name === 'Out of Pocket'
                    ? 'Out of pocket'
                    : patientInfo?.patient_primary_insurance_expiration}
                </Typography>
              </Box>
            </Box>

            <Box sx={classes.user_insurance_container}>
              <Typography sx={classes.user_insurance_title}>
                {t('SecondaryInsurance')}
              </Typography>
              <Box sx={classes.user_insurance_box}>
                <Typography>{t('Name')}</Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo?.secondary_insurance_name}
                </Typography>
              </Box>

              <Box sx={classes.user_insurance_box}>
                <Typography>{t('ID')}</Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo?.secondary_insurance_name === 'Out of Pocket'
                    ? 'Out of pocket'
                    : patientInfo?.patient_secondary_insurance_id}
                </Typography>
              </Box>

              <Box sx={classes.user_insurance_box}>
                <Typography>{t('Expiration')}</Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo?.secondary_insurance_name === 'Out of Pocket'
                    ? 'Out of pocket'
                    : patientInfo?.patient_secondary_insurance_expiration}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={classes.patient_profile_buttons_box} dir={direction}>
          {userRole === 'doctor' || userRole === 'pharmacy_admin' ? (
            <NavLink to={app_paths.NEW_ENCOUNTER.replace(':patientID', patientID)} style={classes.new_encounter_link}>
             <Button sx={[
                classes.patient_profile_button,
                direction === 'ltr' ? { marginRight: '20px' } : { marginLeft: '20px' },
              ]}>
              {t('newEncounter:NewEncounter')}
            </Button>
          </NavLink>
          ) : null}

          <PatientMedicalTable
            open={openDiagnosisMenu}
            handleToggle={handleOpenDiagnosisMenu}
            disabled={!patientDiagnosis || patientDiagnosis.length === 0}
            isActive={openDiagnosisMenu}
            buttonLabel={t('Search.Diagnosis')}
            tableHead={[t('Date'), t('Code'), t('Description')]}
            tableBody={patientDiagnosis}
            bodyCells={['date', 'icd', 'shortdescription']}
          />

          {(company !== companiesObject.speedyRecovery && userRole !== 'pharmacy_admin') && <PatientMedicalTable
            open={openProceduresMenu}
            handleToggle={handleOpenProceduresMenu}
            disabled={!patientProcedures || patientProcedures.length === 0}
            isActive={openProceduresMenu}
            buttonLabel={t('Search.Procedures')}
            tableHead={[t('Date'), t('Code'), t('Description')]}
            tableBody={patientProcedures}
            bodyCells={['date', 'cpt', 'shortdescription']}
          />}

          <PatientMedicalTable
            open={openMedicationsMenu}
            handleToggle={handleOpenMedicationsMenu}
            disabled={!PatientMedications || PatientMedications.length === 0}
            isActive={openMedicationsMenu}
            buttonLabel={t('Search.Medications')}
            tableHead={[t('Date'), t('PackageName'), t('DrugDose'), t('Duration'), t('Frequency')]}
            tableBody={PatientMedications}
            bodyCells={['date', 'packagename', 'drug_dose', 'duration', 'frequency']}
          />
        </Box>

        {patientEncountersError ? (
          <Box dir={direction} sx={classes.alertBox}>
            <Alert severity="error">
              <AlertTitle sx={classes.alertTitle}>{t('PatientEncountersNotFoundTitle')}</AlertTitle>
              {t('PatientEncountersNotFoundMessage', {patientId})}<br />
            </Alert>
          </Box>
        ) : (
          <Box sx={classes.user_encounter_container}>
            <Box sx={{display:"flex", flexDirection:"row", justifyContent: "space-between"}} dir={direction}>
            <Typography sx={classes.sub_title}>{t('EncountersHistory')}</Typography>
            {reloadEncounter()}
            </Box>

            <Box sx={classes.search_container}>
              <FormControl fullWidth sx={classes.search_by} size="small">
                <InputLabel>{t('Search.SearchBy')}</InputLabel>
                <Select
                  label="Search By"
                  value={searchBy}
                  onChange={handleChangeSearchBy}
                  disabled={!patientEncounters || patientEncounters.length === 0}
                >
                  {SearchByMenuItems.map((item) => (
                    <MenuItem key={item.key} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {searchBy === 'date' ? (
              <>
               <SearchInput
               handleSearch={handleSearch}
               type={'date'}
               value={fromDate}
               name='fromDate'
               handleChangeSearch={handleChangeSearch}
               dateValidateError={dateValidateError}
               label={t('Search.FromDate')}
               handleClearDate={() => handleClearDate('fromDate')}
             />
              <SearchInput
               handleSearch={handleSearch}
               type={'date'}
               value={toDate}
               name='toDate'
               setTodayToDate={setToDate}
               handleChangeSearch={handleChangeSearch}
               dateValidateError={dateValidateError}
               label={t('Search.ToDate')}
               handleClearDate={() => handleClearDate('toDate')}
             />
            </>

            ) : searchBy === 'state' ? (
              <Box sx={classes.searchByStateBox}>
                {searchByStateButtons.map((btn, i) => {
                  return <Button
                    key={i}
                    sx={classes.searchByStateButtons}
                    name={btn.name}
                    variant={
                      searchByState === btn.name ? 'contained' : 'outlined'
                    }
                    color={btn.color}
                    onClick={() => handleSelectEncounterState(btn)}
                  >
                    {btn.name}
                  </Button>
                })}
              </Box>
            ) : (
              <SearchInput
              handleSearch={handleSearch}
              type={'text'}
              value={searchQuery}
              handleChangeSearch={handleChangeSearch}
              dateValidateError={dateValidateError}
              searchBy={searchBy}
              searchQuery={searchQuery}
              handleClearSearch={handleClearSearch}
            />
            )}

            {searchBy !== 'state' && (
                    <Button disabled={disaabledSearchButton} variant="contained" onClick={handleSearch} sx={classes.search_button}>
                      {t("Search.Search")}
                    </Button>
                  )}
            </Box>

            {!loadingEcounters && patientEncounters.length > 0 ?
              patientEncounterCards :
              loadingEcounters ?
                <Box sx={classes.loadEncounters}>
                  <CircularProgress/>
                </Box>:
                <Box sx={classes.not_found_message}>
                  <SentimentDissatisfiedIcon />
                  <Typography>{t("PatientEncountersNotFoundTitle")}</Typography>
                </Box>
            }

                {!loadingEcounters && count > 1 && <Box sx={classes.pagination}>
                  <Stack spacing={2} sx={classes.stack}>
                    <Pagination page={page} disabled={count === 1} count={count} variant="outlined" color="primary" size="large" onChange={(e, v) => setPage(v)} />
                  </Stack>
                </Box>}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PatientProfilePage;
